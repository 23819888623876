import axios from 'axios'

//请求拦截
axios.interceptors.request.use(config => {
    const identification = window.localStorage.getItem('identification')
    // if (identification && !(config.url.startsWith('http://') || config.url.startsWith('https://'))) {
    if (identification){
        config.headers.identification = identification
    }
    return config;
})

//响应拦截
axios.interceptors.response.use(response => {
    const identification = response.headers.identification
    if (identification) {
        //保存身份标识到localStorage
        window.localStorage.setItem('identification', identification)
    }
    return response;
})